<template>
  <div class="box">
    <div style="padding: 1rem;">
      <div class="backdiv">
        <div style="padding-top: 1rem;font-size: 1.2rem;;"> 立即支付 </div>
        <div v-if="totlePotin != 0" style="padding-top: 1rem;margin-bottom: 0.5rem; ">
          <span style="color: #D33B2E; font-size: 1.5rem;"> {{ totlePotin }}</span>
          <span style="color: #D33B2E;font-size: 1rem;">点</span>
        </div>

        <div style="margin-bottom: 0.5rem;">
          <span style="font-size: 0.85rem;font-weight: 600;"> 剩余支付时间 {{ countTime }} </span>
        </div>
        <div style="margin-bottom: 0.5rem;">
          <span style="font-size: 0.85rem;color: #999;"> 请尽快支付，超时后系统将取消订单 </span>
        </div>
      </div>
      <div class="backdiv" style="margin-top: 0.5rem;">
        <div style="padding: 1rem;font-size: 1rem;"> 选择支付方式 </div>
        <div class="itemback" @click="onCheckWeiX()">
          <div
            style="align-items: center;text-align: center;display: flex;flex-direction: row; justify-content: space-between;">
            <van-image style="height: 1.5rem; width: 1.5rem;" :src="require('../assets/images/wechat.png')"></van-image>
            <div style="margin-left: 0.8rem;font-size: 1rem;"> 微信支付</div>
          </div>
          <van-checkbox checked-color="#D33B2E" style="margin-left: 0.2rem;" v-model="weichecked" @change="weiChange">
          </van-checkbox>
        </div>
        <div class="itemback" @click="showpop">
          <div
            style="align-items: center;text-align: center;display: flex;flex-direction: row; justify-content: space-between;">
            <van-icon name="card" size="1.5rem" color="#D33B2E" />
            <div style="margin-left: 0.5rem;font-size: 1rem;"> 福利卡支付</div>
          </div>
          <van-checkbox checked-color="#D33B2E" style="margin-left: 0.2rem;" v-model="cardChecked" @change="carChange">
          </van-checkbox>
        </div>
      </div>
      <div class="addCard">
        <span style="margin: 0 0.5rem 0 1.4rem;color: #D33B2E;font-size: 0.8rem;"> {{ tag }}</span>
        <div class="buy" style="margin:0.5rem 1rem 1rem 1rem" @click="payInfo">
          确认支付
          <span v-if="weiPrice > 0 && repairDiffPrice != '0'">{{ weiPrice }}元</span>
        </div>
      </div>
    </div>
    <van-action-sheet v-model="show" title="选择福利卡">
      <div style="max-height: 25rem;">
        <div style="text-align: center;width: 100%;padding-bottom: 1rem;color: #D59A54;">
          <span style="font-size: 0.7rem;">提示：使用多张卡支付订单时，系统依据选卡顺序对卡进行点数消耗</span>
        </div>
        <div v-for="(item, index) in payList" :key="index"
          :class="(repairDiffPrice && repairDiffPrice != item.repairDiffPrice) || (selectLimit > 0 && selectLimit != item.upgradeCardNum) || (wxPoints > 0 && wxPoints != item.wxPoints) ? 'noitemback' : 'itemback'"
          @click="onCheck(item)">
          <div style="display: flex;flex-direction: row;align-items: center; flex: 1;">
            <van-icon name="card" size="1.5rem" color="#D33B2E" />
            <div style="margin-left: 0.8rem; text-align: left; width: 100%;">
              <div v-if="showSelect" style="font-size: 0.9rem;color: #D33B2E;">{{ item.cardPoint }}票
                <!-- <span style="color: #666;font-size: 0.8rem;">(单票{{ item.upgradeCardNum }}点)</span> -->
                <span v-if="item.selectVote" style="color: #666;font-size: 0.8rem; margin-left: 0.2rem;">使用{{
          item.selectVote }}票</span>
              </div>
              <span v-else style="font-size: 0.8rem;">{{ item.name }}</span>
              <!-- <div style="display: flex;flex-direction: row;justify-content: space-between; margin-top: 0.2rem; ">
                <span style="font-size: 0.8rem;">到期日期:{{ $moment(item.expireDate).format("YYYY.MM.DD") }} </span>
              </div> -->
              <div style="display: flex;flex-direction: row;justify-content: space-between; margin-top: 0.2rem; ">
                <span style="font-size: 0.8rem;">卡号:{{ item.cardNo }}
                </span>
              </div>
            </div>
          </div>
          <div
            style=" display: flex;flex-direction: column;min-width: 1.5rem;justify-content: space-between;align-items: end;">
            <div v-if="item.repairDiffPrice == '2'" style="display: flex;flex-direction: row;align-items: center;">
              <van-icon
                :class="(repairDiffPrice && repairDiffPrice != item.repairDiffPrice) || (selectLimit > 0 && selectLimit != item.upgradeCardNum) || (wxPoints > 0 && wxPoints != item.wxPoints) ? 'nostepperback' : 'stepperback'"
                size="1rem" name="minus" @click.stop="minus(item, index)" />
              <span
                :class="(repairDiffPrice && repairDiffPrice != item.repairDiffPrice) || (selectLimit > 0 && selectLimit != item.upgradeCardNum) || (wxPoints > 0 && wxPoints != item.wxPoints) ? 'nostepperback' : 'stepperback'"
                style="margin-left: 0.2rem;font-size: 0.85rem;">
                {{ item.selectVote && item.selectVote > 0 ? item.selectVote : 0 }}</span>
              <van-icon
                :class="(repairDiffPrice && repairDiffPrice != item.repairDiffPrice) || (selectLimit > 0 && selectLimit != item.upgradeCardNum) || (wxPoints > 0 && wxPoints != item.wxPoints) ? 'nostepperback' : 'stepperback'"
                style="margin-left: 0.2rem; " size="1rem" name="plus" @click.stop="plus(item, index)" />
            </div>
            <van-checkbox v-else checked-color="#D33B2E" style="margin-left: 0.5rem;" :value="item.check">
            </van-checkbox>
          </div>
        </div>
        <div>
        </div>
        <div> <span style="margin: 0 0.5rem 0 1.4rem;color: #D33B2E;font-size: 0.8rem;"> {{ tag }}</span> </div>
        <div class="buy" style="margin:1rem" @click="payInfo">
          确认支付
          <span v-if="weiPrice > 0 && repairDiffPrice != '0'">{{ weiPrice }}元</span>
        </div>
      </div>
    </van-action-sheet>
    <!-- <SmartSelect v-if="showSelectCard" :card="selectCard" @closed="closed" @selectNum="getVoteNum"></SmartSelect> -->
  </div>
</template>

<script>
import { SidebarItem, Toast } from "vant";
import SmartSelect from "../components/cashier/SmartSelect.vue";
export default {
  name: 'MovieForGoodsCashier',
  data() {
    return {
      canbuy: true,
      orderId: '',
      sale_price: '',
      weiPrice: 0,
      totlePotin: 0,
      weichecked: false,
      cardChecked: false,
      show: false,
      tag: '温馨提示：请选择支付方式',
      ticketType: 1,  //1 通兑票  2  1.3比例
      repairDiffPrice: null,  //0 不支持补差价 1 普通补差价 2风险补差价
      wxPoints: 0,
      payList: [
      ],
      checked: [],
      cardId: '',
      cityCode: '',
      filmId: '',
      userId: '',
      payPoint: 0,
      cakaOrder: null,
      price: '',
      showSelect: true,
      selectCard: null,
      showSelectCard: false,
      // 抵消的座位数
      seatNum: 0,
      selectLimit: 0,  //选中的面值
      seconds: 600, // 10分钟的秒数
      countTime: '',
      payType:'',
    }
  },

  watch: {
    // $route (to, from) {
    //   alert(to.path)
    // }
  },
  components: { SmartSelect },
  methods: {
    countDown() { // 计算倒计时
      let m = parseInt(this.seconds / 60 % 60);
      m = m < 10 ? "0" + m : m
      let s = parseInt(this.seconds % 60);
      s = s < 10 ? "0" + s : s
      this.countTime = m + ':' + s
    },
    Time() { // 定时器
      if (this.orderCreadTime) {
        let newDate = this.$moment().format('YYYY-MM-DD HH:mm:ss')
        //计算相差多少天，参数可传：day 、second、minute
        let dateRange = this.$moment(newDate).diff(this.$moment(this.orderCreadTime), 'seconds')
        this.seconds -= dateRange
        console.log('seconds-', this.orderCreadTime, '  dateRange', dateRange)
      }
      if (this.seconds < 0) {
        this.countTime = '已超时'
        return
      }
      var timer = setInterval(() => {
        this.seconds -= 1
        this.countDown()
        if (this.seconds == 1) {
          this.countTime = '已超时'
          clearInterval(timer);
        }
      }, 1000)
    },
    //计步器 加
    plus(item, index) {
      if (this.selectLimit > 0 && this.selectLimit != item.upgradeCardNum) {
        console.log('this.selectLimit', this.selectLimit, " this.selectLimit ", this.selectLimit);
        return
      }
      if (this.repairDiffPrice && this.repairDiffPrice != item.repairDiffPrice) {
        console.log('this.repairDiffPrice', this.repairDiffPrice);
        return
      }
      item.selectVote = item.selectVote == undefined ? 0 : item.selectVote
      if (item.selectVote < item.cardPoint) {
        item.selectVote += 1
      }
      if (item.selectVote > 0) {
        item.check = true
        item.timestamp = new Date().getTime();
      }
      this.$set(this.payList, index, item);
      this.onSmartPrice()
    },
    //减
    minus(item, index) {
      if (this.selectLimit > 0 && this.selectLimit != item.upgradeCardNum) {
        return
      }
      if (this.repairDiffPrice && this.repairDiffPrice != item.repairDiffPrice) {
        return
      }
      if (item.selectVote > 0) {
        item.selectVote -= 1
      }
      if (item.selectVote == 0) {
        item.check = false
      }
      this.$set(this.payList, index, item);
      this.onSmartPrice()
    },
    closed(val) {
      this.showSelectCard = val;
    },
    //智选之后计算微信补额
    onSmartPrice() {
      this.selectLimit = 0
      this.repairDiffPrice = null
      //计算卡使用的总点和
      let totleUnUsedPoint = 0
      this.payList.forEach((item) => {
        if (item.check) {
          //选中票金额 重新赋值
          this.selectLimit = item.upgradeCardNum
          this.repairDiffPrice = item.repairDiffPrice
          totleUnUsedPoint += item.selectVote * item.upgradeCardNum
        }
      });
      let totleSurplusPoint = this.totlePotin
      //计算未兑换的总点数
      let totleSurplus = (totleSurplusPoint - totleUnUsedPoint) > 0 ? (totleSurplusPoint - totleUnUsedPoint) : 0
      console.log('this.wxPoints ', this.wxPoints);
      if (this.wxPoints) {
        this.weiPrice = Math.round((totleSurplus * this.wxPoints) * 100) / 100
      } else
        this.weiPrice = Math.round(totleSurplus * 100) / 100
      this.bottomTag()
    },
    onCheckWeiX() {
      this.weichecked = !this.weichecked
    },
    onCheck(val) {
      if (!val.check && this.payList.filter(item => item.check).length >= 5) {
        Toast('该笔订单最多允许五张卡支付');
        return
      }
      if (this.selectLimit > 0 && this.selectLimit != val.upgradeCardNum) {
        Toast('所选卡不能合并支付');
        return
      }
      if (this.repairDiffPrice && this.repairDiffPrice != val.repairDiffPrice) {
        Toast('所选卡不能合并支付');
        return
      }
      if (this.wxPoints > 0 && this.wxPoints != val.wxPoints) {
        Toast('所选卡不能合并支付');
        return
      }
      if (!val.check && this.repairDiffPrice == '0') {
        this.weichecked = false
      }
      this.getSelectPointF(val)
    },
    // 风险补差价 计算路基
    getSelectPointF(val) {
      if (val) {
        val.timestamp = new Date().getTime();
      }
      // 选中票数 初始化
      let sortPayList = []
      this.payList.forEach(item => {
        if (val && val.id == item.id) {
          item.check = !item.check
        }
        if (val && val.repairDiffPrice != item.repairDiffPrice) {
          item.check = false
        }
        item.selectVote = 0
        if (item.check) {
          sortPayList.push(item)
        }
      });
      sortPayList = sortPayList.sort((a, b) => a.timestamp - b.timestamp)
      this.selectLimit = 0
      this.repairDiffPrice = null
      this.wxPoints = 0
      let totleUnSelectPoint = this.totlePotin
      sortPayList.forEach((element, index) => {
        if (element.check) {
          this.selectLimit = element.upgradeCardNum
          this.repairDiffPrice = element.repairDiffPrice
          this.wxPoints = element.wxPoints
          let needTotleVotes = totleUnSelectPoint % this.selectLimit > 0 ? parseInt(totleUnSelectPoint / this.selectLimit) + 1 : (totleUnSelectPoint / this.selectLimit)
          if ((element.cardPoint - element.selectVote) > 0) {
            if (needTotleVotes > (element.cardPoint - element.selectVote)) {
              totleUnSelectPoint = totleUnSelectPoint - (element.cardPoint - element.selectVote) * element.upgradeCardNum
              element.selectVote = element.cardPoint
            } else {
              element.selectVote += needTotleVotes
              totleUnSelectPoint = 0
            }
          }
        }
      });

      this.payList.forEach((item, index) => {
        sortPayList.forEach(element => {
          if (item.id == element.id) {
            item.selectVote = element.selectVote
            this.$set(this.payList, index, item);
          }
        });

      });
      if (this.wxPoints) {
        this.weiPrice = Math.round((totleUnSelectPoint * this.wxPoints) * 100) / 100
      } else
        this.weiPrice = Math.round(totleUnSelectPoint * 100) / 100
      this.bottomTag()
    },
    bottomTag() {
      let totleSelectVotes = 0
      this.payList.forEach(element => {
        totleSelectVotes += element.selectVote ? element.selectVote : 0
      });
      if (totleSelectVotes > 0) {
        this.cardChecked = true
      } else {
        this.cardChecked = false
      }
      this.tag = `使用${totleSelectVotes}票，还需要支付${this.weiPrice}元`
    },
    initProductIds() {
      let ids = ''
      if (this.cakaOrder?.productSubOrderList) {
        this.cakaOrder?.productSubOrderList?.forEach(element => {
          ids = ids + element.productId + ','
        });
      } else if (this.cakaOrder?.orderSortInfoList) {
        this.cakaOrder?.orderSortInfoList?.forEach(element => {
          ids = ids + element.productId + ','
        });
      }
      this.orderPayBenefitCard(ids)
    },
    orderPayBenefitCard(productIds) {
      this.$api
        .queryPayBenefitCardNew(productIds, this.cardId)
        .then((res) => {
          if (res.code == 1) {
            Toast(res.msg);
            return
          }
          this.payList.push(...res.data)
          if (this.payList.length > 0) {
            this.paymentRatio = parseFloat(this.payList[0].paymentRatio)
            this.ticketType = parseFloat(this.payList[0].ticketType)
            // this.repairDiffPrice = this.payList[0].repairDiffPrice
            this.totlePotin = Math.round((this.weiPrice * (this.paymentRatio)) * 100) / 100
            this.payList.forEach(item => {
              item.timestamp = new Date().getTime();
            });
            this.tag = '温馨提示：请选择支付方式不支持退改签'
          }
        })
        .catch((err) => {
          Toast(err.msg);
        });
    },

    carChange() {
      if (this.cardChecked) {
        const checkedCardItem = this.payList.find(item => item.check);
        this.cardChecked = checkedCardItem ? true : false
        if (this.cardChecked) {
          this.weichecked = false
        }
      } else {
        this.payList.forEach((item, index) => {
          item.check = false
          item.selectVote = 0
          item.potintSurplus = parseFloat(item.price * this.paymentRatio)
          item.timestamp = new Date().getTime();
          this.$set(this.payList, index, item);  //使用this.$set更新
        });
        this.getSelectPointF()
      }
    },
    weiChange() {
      if (this.weichecked) {
        this.cardChecked = false
      }
    },
    showpop() {
      if (this.cardChecked) {
        this.cardChecked = false
      } else {
        this.show = true
      }
    },
    directWeChat(params) {
      let this_ = this
      WeixinJSBridge.invoke(
        'getBrandWCPayRequest', {
        "appId": params.appId,     //公众号ID，由商户传入     
        "timeStamp": params.timeStamp,        //时间戳，自1970年以来的秒数     
        "nonceStr": params.nonceStr,//随机串     
        "package": params.package,
        "signType": params.signType,       //微信签名方式：     
        "paySign": params.paySign, //微信签名 
      },
        function (res) {
          Toast.clear();
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            // 使用以上方式判断前端返回,微信团队郑重提示：
            //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
            Toast.success('支付成功');
            this_.goHome();
          } else {
            this.$router.push({
              path: "/AllOrder",
              query: {
                active: 1
              },
            });
          }
        });
    },
    init(params) {
      if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener('WeixinJSBridgeReady', this.directWeChat(params), false)
        } else if (document.attachEvent) {
          document.attachEvent('WeixinJSBridgeReady', this.directWeChat(params))
          document.attachEvent('onWeixinJSBridgeReady', this.directWeChat(params))
        }
      } else {
        this.directWeChat(params)
      }
    },
    getCardIds() {
      let circulateList = []
      this.payList.forEach((item) => {
        if (item.check) {
          let card = {
            cardPoints: item.selectVote,
            cardId: item.id,
            timestamp: item.timestamp
          }
          circulateList.push(card)
        }
      });
      return circulateList.sort((a, b) => a.timestamp - b.timestamp)
    },
    getUnSelectCardIds() {
      let uncardIds = []
      this.payList.forEach((item) => {
        if (!item.check && item.upgradeCardNum == this.selectLimit && item.repairDiffPrice == this.repairDiffPrice) {
          uncardIds.push(...item.id)
        }
      });
      return uncardIds
    },

    payInfo() {
      Toast.loading({
        message: '加载中...',
        forbidClick: true
      });
      if (this.countTime == '已超时') {
        this.$Toast('该订单已超时，请重新下单')
        return
      }
      this.cakaOrder.cardId = this.cardId
      this.cakaOrder.cardIds = ''
      this.cakaOrder.circulateList = this.getCardIds()
      this.cakaOrder.consumptionType = this.weiPrice > 0 ? this.getCardIds().length > 0 ? '2' : '1' : '0'
      this.cakaOrder.orderNo = this.orderId
      this.cakaOrder.wxAmount = this.weiPrice > 0 ? this.weiPrice : ''
      this.cakaOrder.price = this.sale_price
      this.$api
        .payProductInfo(this.cakaOrder)
        .then((res) => {
          if (res.code == 1) {
            Toast(res.msg);
          } else {
            if (this.cakaOrder.wxAmount == 0) {
              Toast('支付成功');
              this.goHome();
            } else {
              this.weiPay()
            }
          }
          Toast.clear();
        })
        .catch((err) => {
          Toast(err.msg);
          Toast.clear();
        });
      // }
    },
    weiPay() {
      let mobile = localStorage.getItem('loginphone')
      this.$api
        .confirmWxByMobile(mobile)
        .then((res) => {
          if (res.code == 0) {
            if (res.data) {
              Toast.clear();
              this.$Dialog.confirm({
                title: "提示",
                message: "微信支付需要先授权",
                confirmButtonText: '去授权',
                cancelButtonText: '取消'
              })
                .then(() => {
                  this.$router.push({
                    path: "/Personal",
                  });
                })
                .catch(() => {
                  // on cancel
                });
            } else {
              this.WXPay()
            }
          } else {
            Toast.clear();
          }
        })
        .catch((err) => {
          Toast.clear();
        });
    },
    WXPay() {
      let form = {
        amount: parseFloat(this.weiPrice),
        userId: this.userId,
        channelOrderNo: this.orderId,
        body: this.orderId,
        consumptionType: this.weiPrice > 0 ? this.getCardIds().length > 0 ? '2' : '1' : '0'
      }
      this.$api
        .generatePayOrderInfo(form)
        .then((res) => {
          if (res.code == 1) {
            Toast.clear();
            Toast(res.msg);
          } else {
            this.init(res.data)
          }
        })
        .catch((err) => {
          Toast.clear();
        });
    },
    goHome() {
      this.$router.replace({
        path: "/CakaOrderDetail",
        query: {
          orderNo: this.orderId,
          orderType: this.payType,
        },
      });
    },

    getDetail(orderNo) {

      this.$api
        .queryPayOrderInfo(orderNo)
        .then((res) => {
          if (res.code === 0) {
            this.cakaOrder = res.data
            this.sale_price = this.cakaOrder.originalPrice
            this.weiPrice = parseFloat(this.sale_price)
            this.price = this.cakaOrder.originalPrice
            this.orderId = this.cakaOrder.orderNo
            this.initProductIds()
          }
        })
        .catch((err) => {
          Toast(err.msg);
        });
    }
  },

  beforeRouteLeave(to, from, next) {
    if (to.path === '/Personal') {
      from.meta.keepAlive = true
    } else {
      from.meta.keepAlive = false
      let Vnode = this.$vnode;
      let parentVnode = Vnode && Vnode.parent;
      if (parentVnode && parentVnode.componentInstance && parentVnode.componentInstance.cache) {
        var key = Vnode.key == null ? Vnode.componentOptions.Ctor.cid + (Vnode.componentOptions.tag ? `::${Vnode.componentOptions.tag}` : '') : Vnode.key;
        var cache = parentVnode.componentInstance.cache;
        var keys = parentVnode.componentInstance.keys;
        if (cache[key]) {
          this.$destroy();
          if (keys.length) {
            var index = keys.indexOf(key);
            if (index > -1) {
              keys.splice(index, 1);
            }
          }
          cache[key] = null;
        }
      }
    }
    next()
  },
  mounted() {
    this.payType = this.$route.query.type

    this.orderId = this.$route.query.order
    this.cardId = this.$route.query.cardId ? this.$route.query.cardId : localStorage.getItem("cardId");
    this.cityCode = localStorage.getItem("cityCode");
    this.sale_price = localStorage.getItem("sale_price");
    this.userId = localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo")).id : ''
    if (this.payType === 'hw') {
      let orderNo = this.$route.query.orderNo
      this.getDetail(orderNo)
    } else {
      this.cakaOrder = JSON.parse(localStorage.getItem('gooddetail'))
      this.sale_price = this.cakaOrder.originalPrice
      this.weiPrice = parseFloat(this.sale_price)
      this.price = this.cakaOrder.originalPrice
      this.orderId = this.cakaOrder.orderNo
      this.initProductIds()
    }



    this.orderCreadTime = this.$route.query.creatTime
    this.Time()
  },
}
</script>
<style lang="scss" scoped>
.box {
  background-color: #f8f8f8;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.stepperback {
  width: 1.4rem;
  height: 1.3rem;
  line-height: 1.3rem;
  text-align: center;
  align-items: center;
  background-color: #f1f1f1;
  color: #666;
}

.nostepperback {
  width: 1.4rem;
  height: 1.3rem;
  line-height: 1.3rem;
  text-align: center;
  align-items: center;
  background-color: #f8f8f8;
  color: #999;
}

.backdiv {
  width: 100%;
  text-align: center;
  align-items: center;
  background-color: white;
  border-radius: 0.5rem;
  padding-bottom: 0.5rem;
}

.selectTag {
  border: 1px solid #d33b2e;
  color: #d33b2e;
  font-size: 0.8rem;
  padding: 0 0.2rem 0.1rem 0.2rem;
  border-radius: 0.2rem;
}

.itemback {
  border-radius: 0.5rem;
  border: 1px solid #CCC;
  padding: 0.8rem 1rem;
  margin: 0 1.1rem 0.8rem 1.1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.noitemback {
  border-radius: 0.5rem;
  border: 1px solid #DDD;
  padding: 0.8rem 1rem;
  margin: 0 1.1rem 0.8rem 1.1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.addCard {
  position: absolute;
  left: 0rem;
  right: 0rem;
  bottom: 1rem;
}

.buy {
  border-radius: 1.5rem;
  padding: 1rem 0;
  font-size: 1rem;
  background-image: linear-gradient(left, #ffcf7a 0%, #ffa976 100%);
  color: #f5f5f5;
  text-align: center;
}

.buyg {
  border-radius: 1.5rem;
  padding: 1rem 0;
  font-size: 1rem;
  background-color: #ccc;
  color: #f5f5f5;
  text-align: center;
}
</style>